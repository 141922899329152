@import "../../../assets/css/variables.scss";

.lic-agreement-div {
  width: 100%;
  text-align: center;
  background: $grey35;
  padding: 15px;
  border-radius: 4px;
  margin-top: 24px;
  overflow: hidden;
  a {
    color: $blue-bg !important;
    text-decoration: none;
    letter-spacing: -0.32px;
    font-weight: 700;
    font-size: 14px;
    padding: 4px 0px 3px 30px;
    background: url("../LicAgreementHeader/images/documentHeaderIcon.svg")
      no-repeat 0px 3px;
  }
 
}
.faq-div-none-url{
  a {
    background: none;
  }
}

.lic-agreement-btn{
  border:none;
}
.faq-header-link
{
  margin:15px 0 0 0;
  .faq-div-none{
    border: none;
  }
  .faq-div-none-url {
    background: none !important;
    border: none;
  }
}