@import "../../../assets/css/variables.scss";

.tableComponent .mylibtablecontainer {
  .mylibtable {
    thead {
      th {
        border-bottom: none !important;
        text-transform: uppercase;
        &:first-child {
          width: 408px !important;
        }
        &:nth-child(2),&:nth-child(3), &:nth-child(4),&:nth-child(5) {
          width: 250px !important;
        }
      }
    }
  }
}

.TableWrapper {
  tbody {
    td {
      &:last-child {
        a.requestIcon {
          background: url("../TableComponent/images/requestoricon.svg") no-repeat 0px
            0px;
          display: inline-block;
          height: 28px;
          width: 28px;
        }
      }
    }
  }
}


.downloadicon {
  background: url("../TempRevisions/images/downloadTRicon.svg") no-repeat 0px 0px !important;
  display: inline-block;
  height: 28px;
  width: 28px; cursor: pointer;
  text-align: left;
  text-indent: -9999px;
  
}
.Toastify__toast-container{width: 460px !important;}
.Toastify__toast-container h1{    font-size: 16px !important;
  margin: 0 !important;font-weight: 700;
  text-align: left;
  padding-bottom: 10px;}
.Toastify__toast{    border: 1px solid #1792E5; padding: 15px;
  border-left: 12px solid #1792e5;box-shadow: 0px 5px 5px -2px #ccc;}
  .Toastify__close-button--light{opacity: 1 !important;}
  .Toastify__toast-container p{text-align: left; margin-bottom: 0 !important; font-size: 14px;}
  .Toastify__toast-icon{
    background: url("../TableComponent/images/tostericon.svg") no-repeat 11px 11px #cae7fa; 
    display: inline-block;
    width: 50px !important;
    height: 50px;
    content: "";
    border-radius: 50%;
  }
  .Toastify__toast-icon svg{display: none;}
  .Toastify__toast-body > div:last-child {
        padding-left: 20px;}
     
      .recentdownloadTable{width: 99%;padding: 32px 0px 24px 0px;}
      .recentdownloadTableTitle {display: inline-block;}
      .recentdownloadTableTitle h1{display: inline;}
      .recentdownloadTable .recentdownloadTablebutton{    display: inline-block;
        float: right;
        border: 1px solid #0071B3;
        border-radius: 4px;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 700;}
        .tableComponent.downloadsTble .tablecontainer .TableWrapper tbody td:last-child {
          text-align: left !important;
      }
      .cls-tempColumn{
        text-align: left;
    }