@import "../../../../assets/css/variables.scss";

.main-panel-class {
	.Leftpanel {
		margin-top: 22px;
	}
}
.FilterFacet.erro-msg{float: right; width: 70%; padding: 63px 40px 40px 40px; }
.Leftpanel {
	.SearchfacetWrapper {
		
		.accordion {
			margin-bottom: 24px;
			border: 1px solid #d0d0d0;
    		border-radius: 4px;
			.accordion-item{border: none;}
			.accordion-button::after{background: url("../FilterFacets/images/accordioncollapseicon.svg") no-repeat 0px 0px;
				display: block; height: 8px; width: 15px; position: absolute;}
			.accordion-item .accordion-button{
				background: $grey35;
				padding: 11px 11px 11px 17px;
				border-radius: 4px !important;
			}
			.accordion-body {
				overflow-y: scroll;
				scrollbar-width: thin;
				padding:16px;
				max-height: 212px;
			
				
				&::-webkit-scrollbar-thumb {
				  background:rgba(0, 0, 0, 0.5);
				  border-radius: 8px;
				}
				&::-webkit-scrollbar {
				  width: 8px;
	
				}
			  
				label{
					display: inline-block;
					font-size: 14px;
					font-weight: 500;
					color: #303030;
					margin-left: 12px;
					width: 239px;
    				overflow: hidden;
    				text-overflow: ellipsis;
    				white-space: nowrap;
					vertical-align: bottom;
					margin-bottom: 0px !important;
				}

				
			}
			.accordion-body {
				& > p {
					margin-bottom: 0;
					padding-bottom: 12px;
					&:last-child {
						padding-bottom: 0px !important;
					}
				}
				.AppliedFiltercomponent{display: inline-block; width: 250px;}
				.Appliedlabel {
					font-weight: 700;
					font-size: 14px;


				}
				.Appliedvalue {
					font-size: 14px;
					font-weight: 500;
					width: 145px;
					

					white-space: normal;
					word-wrap: break-word;
					word-break: break-all;
					font-size: 14px;
					font-weight: 500;
					
					width: auto;
				}
	
				.removeicon {
					background: url("../images/filtertrashicon.svg") no-repeat 0px
					  3px;
					display: inline-block;
					height: 22px;
					width: 17px;
					float:right;
					cursor: pointer;
				}
				.accordionitem {
					display: inline-block;
					.leftcheckbox {
						vertical-align: bottom;
					}
				}
			}
	
			
		}
		.accordion-item {
			.radioLeft {
				vertical-align: bottom;
				border: 1px solid #d0d0d0; 
				border-radius: 50%; 
				width: 15px;
				height: 15px;    
				position: relative; 
				display: inline-block;
    			top: -3px;
			}
			.AppliedFilterHeading {
				position: relative;
				top:6px;
			}
			.clearlink {
				float: right;
				font-size: 14px;
				color: #303030;
				font-weight: 500;
				position: relative;
				top: -5px;
				left: -3px;
			
			}
			.facet-section {
				width: 100%;
				display: inline-block;
				
			}
			.facet-section.AppliedFacet{line-height: 10px;}
		}
		.ApplyButton {
			text-align: right;
			.btn {
				background: #0071b3!important;
				color: #fff!important;
				cursor: pointer;
				&:disabled {
					background: $grey-bg2 !important;
					color: $grey-dark2 !important;
					border: none !important;
				}
				&:hover {
					border: none;
				}
			}
		}
		
		.accordion-header{	
			padding: 0;
			.accordion-button:focus{box-shadow: none;border-bottom: 1px solid #d0d0d0}
			.accordion-button.collapsed:focus {border-bottom: none;}
		}
		.heading-c {
			display: block;
			font-family: "Honeywell Sans Web";
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			text-transform: uppercase;
			color: $grey-dark1;
			padding-left: 27px;
		}
	}
}



	/*Radio button selection css*/
	
	.accordion-body {
		.accordionitem {
			.radioLeft {
				input[type="radio"] {
					-webkit-appearance: none;
					appearance: none;
					background-color: #fff;
					margin: 0;
					appearance: none;
					background-color: white;
					margin: 0;
					width: 17px;
					height: 17px;
					border: 1px solid #d0d0d0;
					border-radius: 50%;
					transform: translate(-0.075em, -0.075em);
					display: grid;
					place-content: center;
				}
				input[type="radio"]::before {
					content: "";
					width: 10px;
					height: 10px;
					border-radius: 50%;
					transform: scale(0);
					transition: 120ms transform ease-in-out;
					box-shadow: inset 1em 1em white;
				}
				input[type="radio"]:checked {
					background-color: #0071b3;
					border: none;
					&::before {
						transform: scale(0.7);
						background-color: #0071b3;
						box-shadow: inset 1em 1em white;
					}
				}
			}
			.leftcheckbox input[type=checkbox] {
				-webkit-appearance: none;
				appearance: none;
			}
		 }
	}
	.Leftpanel {
		.SearchfacetWrapper {
			.accordion {
				.accordion-body {
					&.release-date {
						max-height: 100%;
						overflow-y: unset;
						.customDateLable {font-size: 14px; font-weight: 700; color: #303030;    padding-bottom: 8px;}
						.DateRangePicker_picker {margin-top: -10px;}
						.DateRangePicker {
							display: block !important;
							margin-bottom: 10px;

							
							.DateInput_input {
								font-style: italic;
								padding: 10px 5px 10px 5px;
								text-align: center;
								font-size: 14px;
								font-weight: 500;
								color: #606060;
							}
							.DateRangePickerInput {
								display: block;
							}
							.calander-closeIcon {color: blue; font-weight: 800; padding: 10px;}
							.CalenderCancel {text-align: right;}
							.calander-closeIcon:hover{cursor: pointer;}	
						}
						.DateRangePickerInput_calendarIcon {
							background: url("./images/calendericon.svg") no-repeat 0px 10px !important;
							display: inline-block !important;
							float: right;
							height: 30px;
							width: 25px;
							svg {
								display: none;
							}
						}
						.DateInput_input__small {
							padding: 0px;
						}
						.DateInput_input__focused {
							border: none !important;
						}
						.DateInput__small {
							width: 114px !important;
						}
						.DateRangePickerInput__withBorder {
							border:1px solid #d0d0d0;
							border-radius:4px;
							overflow: hidden;
						}
						.DateRangePickerInput_arrow {position: relative;
							top: -2px;}
						.DayPickerKeyboardShortcuts_buttonReset {
							display: none;
						}
					}
				}
			}
		}
	}
	.DayPicker_transitionContainer {
		min-height: 330px;
	}
	
	.facet-disable{
		pointer-events: none;
	}