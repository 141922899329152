@import "../../../assets/css/variables.scss";

body {
  font-family: "Honeywell Sans Web";
}
.modal.show {
  display: flex !important;
}
/* landing page opacity */
.modal-backdrop.show {
  opacity: 0.75;
}
.modal {
  --bs-modal-width: 712px;
  min-width: 712px;
  margin: 0 auto;
  --bs-modal-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  --bs-modal-border-radius: 4px;
  --bs-modal-border: transparent;
  padding: 24px 24px 28px 24px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
  .modalbodycontent {
    padding-left: 64px;
  }
  .modal-content {
    padding: 24px;
    border: none;
    .modal-body {
      padding: 0;
      margin: 0;
      p {
        margin-top: 8px;
        font-weight: $basefw;
        font-size: 14px;
        font-family: "Honeywell Sans Web";
        color: $grey-dark2;
        letter-spacing: -0.02em;
        margin-bottom: 32px;
      }
    }
    .modal-header {
      .modaltitle,
      .h4,
      .modal-title {
        display: inline-block;
        vertical-align: top;
        font-weight: $fw-xxbold;
        font-size: 18px;
        color: $grey-dark1;
        line-height: 26px;
        font-family: "Honeywell Sans Web";
        &::before {
          height: 48px;
          width: 48px;
          background: url("../GeoCheck/images/globeicon.svg") no-repeat 10px
            10px $grey27;
          border-radius: 50%;
          display: inline-block;
          content: "";
          left: 24px;
          position: absolute;
        }
      }
    }
  }
}
.modal-footer,
.footercontainer {
  display: inline-block;
  width: 100%;
  margin-top: 68px;
}
.modal .modal-content .modal-header,
.modal .modal-content .modal-footer {
  border: none;
  padding: 0;
  margin: 0;
}
.modal-footer {
  .footercontainer {
    .geocheckbtns.rightalignbtn{text-align: right;}
    .geocheckbtns {
      float: right;
      display: inline-block;
      width: 53%;
      .cancelbtn {
        color: $blue-bg ;
        background: $white;
        border: 1px solid $blue-bg !important;
        padding: 14px 37px;
        min-width: auto;
        font-family: "Honeywell Sans Web";
        font-style: normal;
        font-weight: $fw-xxbold;
        margin-right: 5px;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          background: $white;
          color: $blue-bg;
          border: transparent;
        }
      }
      .submitbtn {
        padding: 15px 37px;
        min-width: auto;
        font-family: "Honeywell Sans Web";
        font-style: normal;
        font-weight: $fw-xxbold;
        background: $grey-bg2;
        color: $grey-dark2;
        border: none;
        margin-right: 0;
        font-size: 14px;
        &:hover {
          border: transparent;
          background: none;
          background: $grey-bg2;
          color: $grey-dark2;
          border: none;
          margin-right: 0;
        }
      }
      .submitbtn.active {
        cursor: pointer;
        background: $blue-bg !important;
        color: $white !important;
        &:hover {
          background: $blue-dark;
          color: $white;
        }
      }
      .btn-check {
        &:checked {
          + {
            .btn {
              background: $grey-bg2;
              color: $grey-dark2;
              border: none;
            }
          }
        }
      }
      &:not(.btn-check) {
        + {
          .btn {
            &:active {
             /* background: $blue-bg;
              color: $white;*/
              border: none;
            }
          }
        }
      }
      .btn.active {
        /*background: $blue-bg ;
        color: $white;*/
        border: none;
      }
      .btn.show {
        background: $grey-bg2;
        color: $grey-dark2;
        border: none;
      }
    }
    .constentmsg {
      display: inline-block;
      width: 38%;
      float: left;
      display: inline-block;
      width: 46%;
      input[type="checkbox"] {
        cursor: pointer;
        top: -2px;
        left: -2px;
        width: 20px;
        opacity: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 20px !important;
      }
      .isOptional {
        display: inline-block;
        float: left;
        width: 88%;
        margin-left: 12px;
        font-size: 14px;
        font-weight: $fw-reg;
        font-family: "Honeywell Sans Web";
        color: $grey-dark1;
        letter-spacing: -0.02em;
        line-height: 20px;
      }
      .leftcheckbox {
        color: $grey3;
        border: 1px solid $grey2;
        width: 20px;
        height: 20px;
        float: left;
        display: inline-block;
        margin-top: 8px;
        border-radius: 4px;
      }
      .leftcheckbox.checked {
        background: url("../GeoCheck/images/tickicon.svg") no-repeat 2px 4px
          $blue-bg;
        border-radius: 4px;
        border: 1px solid $blue-bg;
      }
    }
  }
}
.CountryDropdown .css-26l3qy-menu > div  {padding-top: 16px !important; max-height: 250px !important;}
.CountryDropdown .css-1okebmr-indicatorSeparator{display: none;visibility: hidden;}
.CountryDropdown .css-1s2u09g-control{border: 1px solid $grey2 !important; border-radius: 4px;}
.CountryDropdown .css-1s2u09g-control:hover,.css-1s2u09g-control:focus,.css-1s2u09g-control:focus-visible{border: 1px solid $blue-bg !important}
.CountryDropdown .css-1gtu0rj-indicatorContainer{transform: rotateX(180deg);}
.CountryDropdown .css-qc6sy-singleValue{font-size: 16px;font-weight: 500; color: $grey-dark1;}
.CountryDropdown .css-1s2u09g-control div {padding: 0;}
.CountryDropdown .coutry-ddl {width: 464px;}
.CountryDropdown .css-14el2xx-placeholder {font-style: italic; font-weight: 500; font-size:16px; color: $grey-dark2}
.CountryDropdown .css-26l3qy-menu > div {
  overflow-y: scroll;
  scrollbar-color: $grey2 $white;
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background: $grey2;
    border-radius: 8px;
    height: 32px; 
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
}

.CountryDropdown
  .coutry-ddl .css-1s2u09g-control {
  padding: 12px;
  &:focus {
    border-color: $grey2 !important;
    box-shadow: none !important;
  }
}
.CountryDropdown
.CountryDropdown .css-1s2u09g-control.Menu_Open{border:1px solid $blue-bg;}
  .coutry-ddl svg {
  color: $grey-dark1;
}
.CountryDropdown .coutry-ddl .css-26l3qy-menu {
  margin: -2px 0 0 0 !important;
  border: 1px solid $blue-bg;
  border-top: none;
  border-radius: 0px;
}
.CountryDropdown .coutry-ddl .css-26l3qy-menu .css-4ljt47-MenuList div,
.CountryDropdown
  .coutry-ddl
  .css-26l3qy-menu
  .css-4ljt47-MenuList
  .css-1n7v3ny-option,
.CountryDropdown
  .coutry-ddl
  .css-26l3qy-menu
  .css-4ljt47-MenuList
  .css-yt9ioa-option {
  background: none;
  color: $grey-dark1;
}
.CountryDropdown .coutry-ddl .css-26l3qy-menu .css-9gakcf-option,
.CountryDropdown .coutry-ddl .css-26l3qy-menu .css-1n7v3ny-option,
.CountryDropdown .coutry-ddl .css-26l3qy-menu .css-yt9ioa-option {
  background: none !important;
  color: $grey-dark1 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 0px 0px 24px 9px;
}
.CountryDropdown {
	.coutry-ddl {
		width: 464px;
		.css-6j8wv5-Input {
			display: inline-flex;
			margin: 0 !important;
			padding: 0 !important;
			input[type="text"] {
				grid-area: 1 / 2 / 1 / 1 !important;
			}
		}

		.css-1pahdxg-control {
			padding: 5px 0px 5px 9px;
			border-color: $blue-bg !important;
			box-shadow: none !important;
			&:hover {
				border-color: $blue-bg !important;
				box-shadow: none !important;
			}
			&:focus {
				border-color: $blue-bg !important;
				box-shadow: none !important;
			}
			&:active {
				border-color: $blue-bg !important;
				box-shadow: none !important;
			}
		}
		.css-319lph-ValueContainer {
			padding: 0 !important;
			.css-qc6sy-singleValue {
				color: $grey-dark1 !important;
				font-size: 16px;
				font-weight: $fw-reg;
			}
			.css-14el2xx-placeholder {
				font-weight: $fw-reg;
				font-size: 14px;
				font-style: italic;
        color: $grey-dark2;
			}
		}
		.css-1gtu0rj-indicatorContainer {
			transform: rotateX(180deg);
		}
		.css-1hb7zxy-IndicatorsContainer {
			.css-tlfecz-indicatorContainer {
				.css-tj5bde-Svg {
					color: $grey-dark1;
				}
			}
			.css-1okebmr-indicatorSeparator {
				display: none;
				visibility: hidden;
			}
		}
		.css-26l3qy-menu {
			margin: -2px 0 0 0 !important;
			border: 1px solid $blue-bg;
			border-top: none;
			border-radius: 0px;
				
			.css-9gakcf-option {
				background: none !important;
				color: $grey-dark1 !important;
				font-size: 16px !important;
				font-weight: $fw-reg !important;
				padding: 0px 0px 24px 9px;
			}
			.css-1n7v3ny-option {
				background: none !important;
				color: $grey-dark1 !important;
				font-size: 16px !important;
				font-weight: $fw-reg !important;
				padding: 0px 0px 24px 9px;
			}
			.css-yt9ioa-option {
				background: none !important;
				color: $grey-dark1 !important;
				font-size: 16px !important;
				font-weight: $fw-reg !important;
				padding: 0px 0px 24px 9px;
			}
		}
	}
}



