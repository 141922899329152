@import "../../../assets/css/variables.scss";
.aeromain.row {
  margin: 0;
}

.aeromain {
  .row {
    margin: 0;
  }
  .Pagetitle {
   
    padding: 0;
    .title {
      color: $grey-dark1;
      font-style: normal;
      font-weight: 700;
      font-size: 38px;
      line-height: 48px;
    }
  }
  .bradcrumb {
    .breadcrumb {
      .breadcrumb-item {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: $grey-dark1;
        .homeicon {
          height: 30px;
          width: 30px;
          background: url("../breadcrumb/images/homeicon.svg") no-repeat 0px 5px;
          display: inline-block;
          content: "";
          position: absolute;
          &::after {
            content: "";
          }
        }
        &:first-child {
          padding-right: 16.75px;
        }
        + {
          .breadcrumb-item {
            padding-left: 10px;
            padding-top: 5px;
            &::before {
              padding-right: 10px;
            }
          }
        }
      }
      margin: 0 !important;
    }
    margin: 0 !important;
  }
  .AeroTechHeadlineRight.Psearch {
    padding: 0;
   
    a {
      text-decoration: none;
      padding-right: 15px;
      color: $blue-bg;
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      &:hover {
        text-decoration: underline;
      }
    }
    .report-invoice {
      align-items: center;
      display: flex;
      height: 100%;
      padding-right: 10px;
      justify-content: right;
    }
  }
}
