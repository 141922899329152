.my_top_fav {
  .tableComponent {
    .mytop5table {
      width: 99% !important;
    }
    .tablecontainer.mytop5table .TableWrapper {
      thead th:nth-child(2),
      thead th:nth-child(3) {
        width: 374px !important;
      }
      tbody td:last-child {
        text-align: left !important;
      }
      tbody td.addmessage {
        text-align: center !important;
        .messagetouser {
          font-size: 22px;
          font-weight: 700;
          margin-top: 1rem;
        }
      }
    }

  }
  .topfavorites h1 {
    margin-top: 32px;
    line-height: 0.2;
  }
}
