body {
  font-size: 12px;
  font-family: 'Honeywell Sans Web' !important;
}
.bg-grey {
  background-color: #e5e5e5;
  margin-bottom: -48px;
}
.no-data-contracts {
  text-align: center;
  padding: 150px 10px;
}
#myAeroFooterDvNew .footer .mt-5, #myAeroFooterDvNew .footer .my-5.footer-content {margin-top: 100px !important;}
#myAeroFooterDvNew  .footer-content--top{margin-top:80px}
.maincontainer .cls-subscriptionContainer{
  display:block;
}