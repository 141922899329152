@import "../../../assets/css/variables.scss";

.containerReviewRequest {
	border-top: 1px solid $grey2;
	border-bottom: 1px solid $grey2;
	margin: 0 12px 0 12px;
	h2 {
		font-size: 24px;
		font-weight: 700;
		letter-spacing: -0.005em;
		color: $black;
		margin: 0;
		padding: 40px 0 24px 0;
	}
}
.RequestorInfo {
	h1 {
		font-size: 32px;
		font-weight: 700;
		letter-spacing: -0.005em;
		color: $grey-dark1;
		margin: 22px 0 32px 0;
		padding: 0 0 0 12px;
	}
}

.RequestorInforButtons {
	text-align: right;
	margin-top: 40px;
	.btn:hover {
		border: none;	
	}
	.btn-check:checked+.btn:focus-visible, 
	.btn.active:focus-visible, 
	.btn.show:focus-visible, 
	.btn:first-child:active:focus-visible, 
	:not(.btn-check)+.btn:active:focus-visible { box-shadow: none;}
	.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
		background: none;
		border: none;
		color: $blue-bg;
	}
	.backtoLib {
		background: none;
		color: $blue-bg;
		border: none;
		font-size: 14px;
		font-weight: 700;
		font-family: 'Honeywell Sans Web'
	}
	.disabledbtn{
		background:$grey-bg2 !important;
		border:none !important;
		border-radius: 4px !important;
		font-size: 14px;
		font-weight: 700;
		color: $grey-dark2;
		padding: 14px 24px;
		font-family: 'Honeywell Sans Web'

	}
	.disabledbtn.button.button--secondary a, 
	.disabledbtn.secondary-red-cta, 
	.disabledbtn.btn.btn-secondary,
	.disabledbtn.cta.secondary-cta,
	.disabledbtn.button--secondary,
	.disabledbtn.secondary-btn, .disabledbtn.secondary-cta{
		background:$grey-bg2 !important;
		color: $grey-dark2 !important;
	}
	
}

.demoTable {
	thead {
		tr {
			th {
				
				&.cell-address {
					vertical-align: top;
				}
				&:first-child {
					padding-left: 0;
				}
				div {
					&:first-child {
						color: $grey-dark1;
						font-size: 16px;
						font-weight: 700;
						font-family: 'Honeywell Sans Web';
					}
					&:nth-child(2) {
						color: $grey-dark1;
						font-size: 14px;
						font-weight: 500;
						font-family: 'Honeywell Sans Web';
					}
				}
			}
			&:first-child th {
				padding-top: 0px;
			}
		}
		
	}
}
.demoTable .cell-one,.demoTable .cell-two,.demoTable .cell-address {
	border: none !important; width: 35%;
}

