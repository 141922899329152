@import "../../../../assets/css/variables.scss";

.TotalRecordsCount {
  p {
    font-weight: 500;
    margin-bottom: 0px;
    font-size:16px;
    color:$grey-dark9;
  }
}
