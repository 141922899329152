$white: #fff;
$black: #000;
$blue: #1274b7;
$blueLight: #f0f7ff;
$blue-bg: #0071b3;
$blue-dark: #025e94;
$blue1: #1792e5;
$contrast-blue: #137bc0;
$blue2: #0e5f95;
$darkBlue: #1272b2;
$blue-hov: #00629b;
$light-blue: #f0f2f7;
$green: #7eb338;
$green2: #628b2c;
$defaultRed: #ff0000;
$red: #ee3124;
$red1: #f15a4f;
$red2: #cc0000;
$orange: #f37021;
$fw-light: 300;
$fw-reg: 500;
$fw-bold: 800;
$fw-xbold: 900;
$fw-xxbold: 700;
$fw-regbold: 800;
$grey1: #707070;
$grey2: #d0d0d0;
$grey3: #9b9b9b;
$grey4: #808080;
$grey5: #d7d7d7;
$grey6: #f7f7f7;
$grey7: #e0e0e0;
$grey8: #404040;
$grey9: #a0a0a0;
$grey10: #979797;
$grey11: #d7d7d7;
$grey12: #595858;
$grey13: #d8d8d8;
$grey14: #f0f0f0;
$grey15: #6c6c6c;
$grey16: #e8e8e8;
$grey17: #6e6e6e;
$grey18: #e6e6e6;
$grey19: #4a4a4a;
$grey20: #d7d7d7;
$grey21: #c0c0c0;
$grey21: #cccccc;
$grey22: #bababa;
$grey23: #e7e7e7;
$grey24: #e5e5e5;
$grey25: #e4e4e4;
$grey26: #f8f8f8;
$grey27: #eee;
$grey28: #777;
$grey29: #757575;
$grey30: #f9f9f9;
$grey31: #d0e9f9;
$grey32: #c9c7c7;
$grey33: #c4c4c4;
$grey34: #b0b0b0;
$grey35: #f3f3f3;
$grey-dark1: #303030;
$grey-dark2: #606060;
$grey-dark3: #505050;
$grey-dark4: #3c3c3c;
$grey-dark5: #535353;
$grey-dark6: #464545;
$grey-dark7: #282c34;
$grey-dark8: #303130;
$grey-dark9: #1d1d1d;
$grey-bg: #f6f6f6;
$grey-bg1: #f2f4f7;
$grey-bg2: #dfe0df;
$grey-bg3: #b4b4b4;
$dark-Orange: #ff8c00;
$grey-bg4: #e1e1e1;
$greey-bg5: #393939;
$light-whilte: #deebff;

$xmediumbreakpoint: 992px;
$mediumbreakpoint: 768px;
$smallbreakpoint: 480px;

$mobile-nav-z-index: 900;
$blue3: #286090;
$color_transparent: transparent;

$lh-15: 1.5;

$font-family-honeywellsans: "honeywell sans";
$basefw: 500;
