.modal-body .note-info {
    color: #0071b3;
    font: normal 500 16px "Honeywell-Medium";
    border: 1px solid #0071b3;
    padding: 14px;
    margin-top: 14px;
    display: flex;
    align-items: center;
  }
  .modal-body .note-info h3 {
    color: #0071b3;
    font: normal 900 22px "Honeywell Sans web";
  }
  .modal-body .notes-cnt .note-info p {
    font: normal 500 16px "Honeywell Sans Web";
    margin-bottom: 8px;
    color: #0071b3;
  }
  .modal-body .note-info>.info-icon-message {
    width: 52px;
    height: 22px;
    border-radius: 20px;
    color: #0071b3;
    font: normal bold 14px "Honeywell Sans Web";
    text-align: center;
    border: 2px solid #0071b3;
    margin: 0 12px 0 0;
  }
  .notes-cnt h2{
    font: normal bold 18px "Honeywell-Medium";
  }
  div[aria-labelledby="welcome popup"] {
     .modal-content .modal-header .modal-title::before{
      display :none;
     }
    
  }