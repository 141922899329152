@import "../../../assets/css/variables.scss";
button:focus-visible{outline: none;}
.searchRecords {
	.rightpanel {
		
			.favlinks {
				border: none;
				padding: 0px;
				font-size: 14px;
				font-weight: 700;
				color: $blue-bg;
				font-family: 'Honeywell Sans Web';
				padding-left: 20px;
				padding-bottom: 14px;
				text-decoration: none;
			}
			.addfav {
				background: url("../SearchResults/images/addfavicon.svg") no-repeat 0px 4px;
			}
			.removefav {
				background: url("../SearchResults/images/removefavicon.svg") no-repeat 0px 4px;
			}
			.getemail{
				background: url("../SearchResults/images/getemailicon.svg") no-repeat 0px 4px;
			}
			.stopemail{
				background: url("../SearchResults/images/stopemailicon.svg") no-repeat 0px 4px;
			}
			.viewcollection{
				background: url("../SearchResults/images/viewcollectionicon.svg") no-repeat 0px 4px;
			}
			.Temprevision{
				background: url("../SearchResults/images/temporaryrevisionicon.svg") no-repeat 0px 4px;
			}
			.RelatedPub{
				background: url("../SearchResults/images/relatedPubicon.svg") no-repeat 0px 4px;
			}
			.reportdiscripency{
				background: url("../SearchResults/images/reportdiscripencyicon.svg") no-repeat 0px 4px;
				
			}
			.requestdownload{
				background: url("../SearchResults/images/requestdownloadicon.svg") no-repeat 0px 4px;
				
			}
			.downloadTR {
				background: url("../SearchResults/images/requestdownloadicon.svg") no-repeat 0px 4px;
				
			}
			.requestSubscription {
				background: url("../SearchResults/images/requestSubs.svg") no-repeat 0px 6px;
				
			}
			.requestCollection {
				background: url("../SearchResults/images/requestcollection.svg") no-repeat 0px 4px;
				
			}
			.requestPublication {
				background: url("../SearchResults/images/requestPub.svg") no-repeat 0px 4px;
				
			}
			.interactivemanual {
				background: url("../SearchResults/images/interactivemanualicon.svg") no-repeat 0px 4px;
			}

	}
}
.favPopup {
	.modal-content {
		width: 500px;
		.popupmsg{ padding: 0 !important;}
		.popupmessageSuccess{padding-left: 65px !important;}
		.popupmessageSuccess::before {
			background: url("../SearchResults/images/greentick.svg") no-repeat 10px 10px $grey27 !important;
			border-radius: 50%;
			content: "";
			display: inline-block;
			height: 48px;
			left: 0px;
			position: absolute;
			width: 48px;
			top: -3px;
		}
		.modal-footer {
			text-align: right;
			.Popupfooter {
				margin-top: 15px;
				.geocheckbtns{width: 100%;}
			}
			.myfavlink {
				background: none !important;
				border: 1px solid $blue-bg;
				min-width: auto;
				text-decoration: none;text-transform: uppercase;border-radius: 4px;top: 2px;
				position: relative;
				
			}
			.managefavlink {
				text-decoration: none;text-transform: uppercase;border-radius: 4px;top: 2px;
				position: relative;
			}
		}
		.modal-header {
			.btn-close {
				font-size: 10px;
				position: relative;
				top: -19px;
				right: -9px;
			}
		}
	}
}

.quicklinkserrorPopup  .modal-footer .submitbtn.active{
background: #0071b3!important;
    color: #fff!important;
    cursor: pointer; border: none;}
	.quicklinkserrorPopup  .modal-footer .submitbtn{
	background: #dfe0df;
    border: none;
    color: #606060;
    font-family: Honeywell Sans Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin-right: 0;
    min-width: auto;
    padding: 14px 37px;}

	.modal .modal-content .quicklinkserrorPopup  p{font-size: 18px;
		font-weight: 600;
		margin-bottom: 0;}
		.searchRecords .rightpanel .favlinks.disableButton{pointer-events: none;}