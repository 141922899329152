@import "../../../assets/css/variables.scss";
@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  animation: 1s blink infinite;
  font-size: 30px;
}
.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}
.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}
.downloadPopup .info,
.downloadPopup .fetchingData,
.downloadPopup .msgtouser,
.downloadPopup .progress-baar.progress {
  margin-bottom: 20px;
}
.downloadPopup {
  .modal-dialog
  {
    width: auto;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .info {
    font-size: 14px;
    font-weight: 500;
    color: $grey-dark2;
  }

  .fetchingData {
    font-size: 16px;
    font-weight: 700;
    color: $grey-dark2;
  }
  .msgtouser {
    font-size: 12px;
    font-weight: 700;
    color: $grey-dark2;
  }

  @mixin titleStyle($iconUrl) {
    padding: 0;
    h6 {
      font-size: 18px;
      font-weight: 700;
      padding-left: 65px;
    }
    &::before {
      background: url($iconUrl) no-repeat 10px 10px $grey27 !important;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 48px;
      width: 48px;
      left: 0px;
      position: absolute;
      top: 13px;
    }
  }

  .downloadTitle {
    @include titleStyle(
      "../../common/RequestDownload/images/filedownloadicon.svg"
    );
  }

  .popupBlockedTitle {
    @include titleStyle("../../common/RequestDownload/images/warningIcon.svg");

    &::before {
      background-position: center !important;
    }
  }
  
  .errorTitle {
    padding: 0;
    margin: 0 0 15px 0;
    .error-title
    {
      font-size: 20px;
      font-weight: 700;
    }
    h6 {
      font-size: 18px;
      font-weight: 700;
      padding: 0 0 0 55px;
    }
    &::before {
      background: url("../../common/RequestDownload/images/warningIcon.svg")
        no-repeat 10px 10px $grey27 !important;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 48px;
      left: 0px;
      position: absolute;
      width: 48px;
      top: 13px;
      background-position: center !important;
      margin-left: 15px;
    }
  }

  .modal-content {
    .modal-body {
      &.downloadPub {
        padding-left: 65px;
      }
    }
  }

  .progress-baar {
    &.progress {
      margin-top: 8px;
      height: 25px;
    }
  }
  .downloadlingstatus {
    color: $grey-dark2;
    font-weight: 700;
    font-size: 16px;
  }
}

.heightBlockerPopup {
  height: 185px;
}

.modal .modal-content .modal-body .popupBlockedMessage {
  color: $defaultRed;
  margin-bottom: 1.2rem;
  font-weight: 500;
  font-size: 16.5px;
}

.modal .modal-content .modal-body .popupBlockedLink {
  font-weight: 600;
}

.close-modal {
  background-color: transparent;
  border: none;

  .close-icon {
    width: 30px;
    height: 30px;
    stroke-width: 2;
    position: absolute;
    right: 16px;
    top: 18px;
    background-image: url("./images/closeIcon.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.close-modal {
  background-color: transparent;
  border: none;

  .close-icon {
    width: 30px;
    height: 30px;
    stroke-width: 2;
    position: absolute;
    right: 16px;
    top: 18px;
    background-image: url("./images/closeIcon.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}
