

@import "../../../assets/css/variables.scss";


.cls-relatedModalPopUp {
  .modal-dialog{
    width: auto;
  }
  .modal-content {
    width: 545px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    .cls-reqpubcontainer {
      width: 425px;
      margin: 0 0 0 72px;
      position: relative;
    }
    .cls-icondisclaimer {
      position: absolute;
      top: 0;
    }
    .cls-related-download-content {
      position: relative;
    }
    .cls-iconclose {
      position: absolute;
      right: 0;
      top: -3px;
      cursor: pointer;
    }
    .cls-request-download-text {
      font-size: 18px;
      font-weight: 700;
    }
    .cls-related-downoad-message {
      font-size: 14px;
      color: $grey-dark2;
      font-weight: 500;
      margin: 8px 2px 0 0;
    }
    .cls-related-downoad-remarks {
      margin: 10px 0 0 0;
      .cls-related-downoad-remarks-title {
        font-size: 14px;
        font-weight: 700;
      }
      .cls-related-downoad-remarks-description {
        margin: 10px 0 0 0;
        font-size: 14px;
        width: 99%;
        padding: 16px 12px;
        font-weight: 500;
        border: 1px solid $grey2;
        border-radius: 4px;
      }
    }
  }

  .modal-footer {
    text-align: right;
    display: flex;
    .footercontainer {
      .geocheckbtns {
        .cancelbtn {
          font-size: 16px;
          width: 130px;
          padding: 14px 16px;
        }
        .acknowledgebtn
        {
          font-size: 16px;
          width: 190px;
          padding: 14px 16px;
          color: $white;
          background: $blue-bg;
          border: 1px solid $blue-bg;
          font-weight: 700;
        }
        .submitbtn {
          font-size: 16px;
          width: 240px;
          padding: 14px 16px;
          border: 1px solid $blue-bg;
        }
      }
    }
  }
}

.cls-partialdownload-popup.modal .modal-content{
        width:100%;
        padding: 24px 24px 24px 96px;
    
}
.cls-partialmodal-body.modal-body {
  padding: 0px 4px 0px 0px;
  overflow: hidden;
}

.cls-partialdownload-radioContent {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $black;
  margin-bottom: 20px;
}

.cls-radio-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.cls-partialdownload-inputype {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cls-label {
  margin-left: 5px;
}

.cls-partialdownload-heading{
h4{
    font-weight:700;
    font-size:18px;
    line-height: 26px;
    color:$grey-dark1;
    width:196px;
    height:26px;
}
&::before {
    background: url("../../common/RequestDownload/images/partialmedia.svg")  !important;
    border-radius: 50%;
    display: inline-block;
    height: 48px;
    color:$grey9;
    position: absolute;
    width: 48px;
   
}
}

p.cls-partialdownload-content {
    font-weight:500;
    font-size:14px;
    line-height: 20px;
    color:$grey-dark2;
    width:421px;
    height:40px;
    margin-bottom: 20px;
   
}
.cls-partialdownload-radioContent{
    width:294px;
    font-weight:500;
    font-size:14px;
    line-height: 20px;
    color:$black;
    width:421px;
}
.cls-label{
    padding-left:10px;
    font-weight:500;
    font-size:14px;
    line-height: 20px;
    color:$grey-dark1;

}
span.cls-partialdownload-inputype {
    padding-left: 8px;
    
}
.cls-partialdownload-inputype input[type="radio"]{
  height: 20px;
  width: 13px;
}
input[type="radio"]{
    height: 20px;
    width: 20px;
    vertical-align: sub;
}
.cls-partialdownload-button.btn.btn-primary{
    background-color:$blue-bg;
    width:131px;
    height:48px;
    padding: 0px 12px 0px 12px;
    color:$white;
    font-weight:700;
    font-size:16px;
    line-height: 20px;
    min-width:110px;
}
button.cls-partialdownload-cancelButton.btn.btn-primary{
    background-color:$white;
    width:131px;
    height:48px;
    padding: 0px 12px 0px 12px;
    color:$blue-bg;
    font-weight:700;
    font-size:16px;
    line-height: 20px;
    border:1px solid $blue-bg;
    margin-left:147px;
    min-width:110px;

}
.cls-closebutton{
color:$grey-dark1;
}