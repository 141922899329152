@import "../../../assets/css/variables.scss";
 
      .TRTable {
            thead {
                th {
                  text-transform: uppercase;
                  &:first-child{
                    width: 250px !important;
                  }
                  &:nth-child(2) {
                    width: 400px !important;
                  }
                  &:nth-child(3) {
                    width: 600px !important;
                  }
                  &:nth-child(4) {
                    width: 195px !important;
                    text-align: center;

                  }
            }    
        
        }
    }

    .temp-remarks {
      text-align: left;
  }