@import "../../../assets/css/variables.scss";
.myfavpagination {
  ul {
    display: inline-block;
    text-align: center;
    margin: 0px 10px;
    padding:14px 0px;
    border: 1px solid #ccc;
    border-top:none !important;
    width: 99%;
    li:first-child .Mui-disabled.MuiPaginationItem-firstLast::before{content:"<<"}
      li:first-child .MuiPaginationItem-firstLast::before{content:"<<"}
      li:nth-child(2) .Mui-disabled.MuiPaginationItem-previousNext::before{content:"<"}
      li:nth-child(2) .MuiPaginationItem-previousNext::before{content:"<"}

      
      .Mui-disabled.MuiPaginationItem-firstLast:last-child::before{content:">>"}
      .MuiPaginationItem-firstLast:last-child::before{content:">>"}
      .Mui-disabled.MuiPaginationItem-previousNext:last-child::before{content:">"}
      .MuiPaginationItem-previousNext:last-child::before{content:">"}
    li{
      display: inline-block;
      padding: 0px 12px;
      
      
      div.MuiPaginationItem-text{padding: 0; margin: 0;}
      button{
        width: auto;
        height: auto;
        padding: 5px 0px;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #404040;
        svg{
            display: none;
          }
      }
      button.Mui-selected,button.Mui-selected:hover{
        background: rgba(18, 116, 183, 0.1);
        border-radius: 8px;
        color: #10659F;
         
      }
    }
  }
}
.mylibsearchbar {
	margin: 0px 0px;
  outline: 1px solid $grey2;
  padding: 8px;
  width: 99%;

	input {
    border: 1px solid $grey2;
		border-radius: 4px;
		padding: 6px 23px 6px 12px;
    
		&::placeholder {
			font-style: italic;
			font-size: 14px;
			font-weight: 500;
			color: $grey-dark2;
		}
    &:focus{
      outline: $grey2;
    }
	}
  
}