@import "../../../assets/css/variables.scss";

.excelDownload {
  .btnDownload {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .text {
      font-size: 16px;
      font-weight: 700;
      color: $blue;
      margin: 0px;
    }
  }
  .downloadIcon {
    width: 24px;
    height: 24px;
  }
}
