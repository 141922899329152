@import "../../../assets/css/variables.scss";
.Pagetitle {
 
  padding: 0;
  .title {
    color: $grey-dark1;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
  }
}
.bradcrumb {
  .breadcrumb {
    .breadcrumb-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: $grey-dark1;
      .homeicon {
        height: 30px;
        width: 30px;
        background: url("../breadcrumb/images/homeicon.svg") no-repeat 0px 5px;
        display: inline-block;
        content: "";
        position: absolute;
        &::after {
          content: "";
        }
      }
      &:first-child {
        padding-right: 16.75px;
      }
      + {
        .breadcrumb-item {
          padding-left: 10px;
          padding-top: 5px;
          &::before {
            padding-right: 10px;
          }
        }
      }
    }
    margin: 0 !important;
  }
  margin: 0 !important;
}
.AeroTechHeadlineRight.Psearch {
  padding: 0;
 
  a {
    text-decoration: none;
    padding-right: 27px;
    color: $blue-bg;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:last-child {
      padding-right: 0px;
    }
  }
  .report-invoice {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 22px;
    justify-content: center;
  }
  .AdminLinks > a{
    background: url("../AdminSite/images/adminsitedowncarroticon.svg") no-repeat 100px 15px $blue-bg;
    color: white;
    padding: 10px 30px 10px 15px;
    border-radius: 4px;
  }
  .AdminLinks a.close{ 
    background: url("../AdminSite/images/adminsitecarroticon.svg") no-repeat 100px 15px $blue-bg;
  }
    .AdminSiteCls div a{ 
      background: url("../AdminSite/images/adminlicensedownloadicon.svg") no-repeat right 0px;
    padding-bottom: 5px;
    padding-right: 25px;}
}
//.row.parentrow {padding-right: 0px !important;}

// .aeromain {
// 	.AeroTechHeadlineRight {
// 		&.Psearch {
// 			.report-invoice {
// 				span {
// 					&.navbarActive {
// 						color: $blue-bg;
// 						text-decoration: none;
// 						position: relative;
// 						&::after {
// 							content: '';
// 							width: 100%;
// 							position: absolute;
// 							left: 0;
// 							bottom: -8px;
// 							border-bottom: solid 2px $blue-bg;
// 							cursor: default;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }
  
  
.navbarActive1 {
  color: $blue-bg;
  text-decoration: none;
  position: relative;
}
.navbarActive1::after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -8px;
    border-bottom: solid 2px $blue-bg;
    cursor: default;
  }
  
    
.navbarActive {
	>span {
		color: $blue-bg;
		text-decoration: none;
		position: relative;
		&::after {
			content: '';
			width: 100%;
			position: absolute;
			left: 0;
			bottom: -8px;
			border-bottom: solid 2px $blue-bg;
			cursor: default;
		}
	}
}
.AeroTechHeadlineRight.Psearch a.faq{
  padding-right: 32px;
  &::after{
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    background: url("../breadcrumb/images/faq.svg") no-repeat 4px 2px;
    background-size: 12px;
  }
}