@import "../../../assets/css/variables.scss";

.customsearchbar {
  
  margin: 18px 0 24px 0;
  .tech-searchbar {
    .searchbarwrap {
      padding: 20px 5px;
      display:flex;
      align-items: center;
      justify-content: stretch;
      .searchbarcontainer {
        padding: 0;
        margin: 0 12px 0 5px;
    }
    .find-pub{
      min-height: 42px;
      h2{
        color: $grey-dark1;
        font: normal bold 32px/40px "Honeywell Sans Web";
      }
    }
    .startover,.or_block,.blockcl {
      margin-top: 42px;

    }

  }
 }
}
.searchbarwrap {
  .searchbarcontainer {
    .custominputgroup {
      label {
        width: 100%;
        margin-bottom: 0 !important;
      }
    }
    .searchbox {
      min-height: 60px;
      font-family: "Honeywell Sans Web";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: -0.02em;
      color: $grey-dark1;
      background: $blueLight;
      border: 2px solid $blueLight;
      border-radius: 4px !important;
      &:hover,&:active{
        border: 2px solid $blue-bg;
      }
      &::-ms-input-placeholder {
        color: $grey-dark1;
      }
    }
  }
}
.tech-searchbar {
  .searchbarwrap {
    .searchbarcontainer {
      .searchinput {
        padding: 15px 16px;
      }
    }
  }
  .searchinput {
    &:focus {
      border: 1px solid $grey2;
      box-shadow: none;
    }
  }
}
.searchbarcontainer {
  .custominputgroup {
    .search-icon-div {
      position: relative;
      left: -3rem;
      z-index: 9;
      .searchiconfigma {
        position: absolute;
        top: 1px;
        bottom: 1px;
        width: 40px;
        height: 40px;
        background: $blue-bg;
        margin: 8px 8px 8px 0px;
        padding: 10px 9.69px 9.69px 10px;
        border-radius: 4px;
        background-image: url("../SearchBar/searchiconfigma.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.loader {
  position: relative;
  top: 9px;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 35px;
  height: 35px;
  margin-left: 6px;
  display: none;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  left: 50%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.InvalidRequest .error-msg {
  text-align: center;
 padding-top: 10px;
  display: inline-block;
  width: 100%;
}
.blockclass{
 
  width:82%;
  
  }
  .blockclass-startover
  {
    
    width:82%;
    .find-pub>h2{
      display: none;
    }
    
  }
  .or_block
  {
   
    width:auto;
    color: $grey-dark1;
    font: normal bold 18px/40px "Honeywell Sans Web";
    
  }
   .blockcl{
    
    width: auto;
   } 
 
   .submitbtn-browseby{
    color:$white;
     &.btn.btn-primary{
      min-width: 216px;
      padding:14px 16px;
      border:none;
      background-color: $blue-bg;
      font-size:14px;
      font-weight: 700;
      white-space: nowrap;
    }
   }

   .startover{
     .submitbtn-browseby{
         display:flex;
         align-items: center;
         justify-content: center;
         width:100%;
        
       .cls_clear_search{
          margin:0 5px 0 0;
       }  
     }
   }

     
 