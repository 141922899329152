@import "../../../assets/css/variables.scss";
a {
  text-decoration: none !important;
}
.my_top_fav {
  .mywatchlist {
    p {
      margin: 0 10px 0px 0px;
      width: 639px;
      line-height: 19px;
      font-weight: $basefw;
      font-size: 16px;
      color: $grey-dark9;
    }
    .mywatchlisttable {
      .TableWrapper {
        td.pubtitlecell {
          a {
            font-size: 16px;
            font-weight: 700;
            font-family: "Honeywell Sans Web";
            text-decoration: none;
            width: 170px;
            display: block;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
          }
        }
        td.dateformat {
          text-align: center;
        }
        thead {
          th {
            &:first-child {
              width: 170px !important;
            }
            &:nth-child(2) {
              width: 165px !important;
            }
            &:nth-child(3) {
              width: 148px !important;
            }
            &:nth-child(4) {
              width: 100px !important;
            }
            &:nth-child(5) {
              width: 100px !important;
              text-align: left;
            }
            &:nth-child(6) {
              width: 135px !important;
              text-align: center;
            }
            &:nth-child(7) {
              width: 90px !important;
              text-align: center;
            }
            &:nth-child(8) {
              width: 105px !important;
              text-align: center;
            }
            &:last-child {
              width: 80px !important;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.modalforwatchlist,
.stopEmailDeletePopup .modalbodycontent {
  padding: 0 !important;
  .removedpopupconfm {
    margin-bottom: 0 !important;
  }
}

.emptyWrapper {
  margin-top: 25px;
}

.ExcelExporterWrapper {
  margin-right: 12px;
  display: flex;
  justify-content: flex-end;
  margin-top: -18px;
  margin-bottom: 20px;
}
.ExcelExporterWrapper .excelDownload .btnDownload .text {
  width: max-content;
}

.tableComponent {
  width: 100%;
  margin: 0;
  padding-left: 10px;
}
