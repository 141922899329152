@import "../../../assets/css/variables.scss";
button:focus-visible {
  outline: none;
}
body {
  font-family: "Honeywell Sans Web";
}
.searchRecords {
  .rightpanel {
    .favlinks {
      border: none;
      padding: 0px;
      font-size: 14px;
      font-weight: 700;
      color: $blue-bg;
      font-family: "Honeywell Sans Web";
      padding-left: 20px;
      padding-bottom: 14px;
      &:last-child {
        padding-bottom: 0 !important;
      }
    }
    .addfav {
      background: url("../SearchResults/images/addfavicon.svg") no-repeat 0px
        4px;
    }
    .removefav {
      background: url("../SearchResults/images/removefavicon.svg") no-repeat 0px
        4px;
    }
    .getemail {
      background: url("../SearchResults/images/getemailicon.svg") no-repeat 0px
        4px;
    }
    .stopemail {
      background: url("../SearchResults/images/stopemailicon.svg") no-repeat 0px
        4px;
    }
  }
}
.favPopup {
  .modal-content {
    width: 500px;
    .popupmsg {
      padding: 0 !important;
    }
    .popupmessageSuccess {
      padding-left: 65px !important;
    }
    .popupmessageSuccess::before {
      background: url("../SearchResults/images/greentick.svg") no-repeat 10px
        10px $grey27 !important;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 48px;
      left: 0px;
      position: absolute;
      width: 48px;
      top: -3px;
    }
    .modal-footer {
      text-align: right;
      .Popupfooter {
        margin-top: 15px;
        .geocheckbtns {
          width: 100%;
        }
      }
      .myfavlink {
        background: none !important;
        border: 1px solid $blue-bg;
        min-width: auto;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 4px;
        top: 2px;
        position: relative;
      }
      .managefavlink {
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 4px;
        top: 2px;
        position: relative;
      }
    }
    .modal-header {
      .btn-close {
        font-size: 10px;
        position: relative;
        top: -19px;
        right: -9px;
      }
    }
  }
}
.backtosearch {
  position: relative;
  a.backtosearchlink {
    color: $blue-bg;
    font-family: Honeywell Sans Web;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    background: url("../ViewCollection/images/backarrowicon.svg") no-repeat 27px
      18px;
    border: 1px solid $blue-bg;
    border-radius: 4px;
    padding: 15px 24px 15px 54px;
    position: absolute;
    top: -50px;
    right: 10px;
  }
}

.AeroTechHeadlineRight.viewcollection {
  display: none;
}
.ViewcollectionContainer .searchRecords {
  width: 100%;
  margin-left: 0px;
  .item .metafield .metafieldright {
    width: 83%;
  }
  .item .metafield .metafieldLeft {
    width: 17%;
  }
  .seacrhresultLeft {
    width: 81%;
  }
}

.viewcollectiontab {
  .mb-3 {
    padding: 0px 0px 0 0px;
    margin: 0px 0px 22px 12px !important;
    .manualstab .manualstitle {
      margin-bottom: 8px;
      font-weight: 700;
      line-height: 2rem;
      color: $greey-bg5;
      font-size: 24px;
      letter-spacing: -0.08px;
    }
  }
  .nav-item {
    button {
      color: $grey-dark1;
      border-style: none;
      padding: 0 48px 0 0;
      margin: 10px 0 0 0;
      font-family: "Honeywell Sans Web";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.32px;
    }
    .nav-link {
      &.active {
        color: $blue-bg;
        font-weight: 700;
        &::after {
          content: "";
          display: block;
          height: 4px;
          width: 100%;
          background-color: $blue-bg;
          margin-top: 10px;
        }
      }
    }
  }
}

.ViewcollectionContainer {
	.WrapperContainer {
		.searchRecords {
			.item {
				.itemTitle {
					h6 {
						margin-top: 37px;
					}
				}
			}
		}
	}
	.viewcollectiontab {
		.WrapperContainer {
			.searchRecords {
				.rightpanel {
					margin-top: 41px;
				}
			}
		}
	}
	.searchRecords {
		.item {
			.metafield {
				&:last-child {
					margin-bottom: 0px !important;
				}
			}
		}
	}
}

.sortby-relevance {
  width: 18%;
  float: right;
   margin-bottom: 13px;
}
.manualstitle {
  display: inline-block;
  padding-bottom: 15px;
}
.manualstab >div {
  padding: 0px 0px 0px 12px;
  margin-left: 0px;
}
.err-msg {
  text-align: center;
}
.ViewcollectionContainer {
	display: inline-block;
	width: 100%;
	padding: 26px 12px 0 12px;
  margin: 0;
	.WrapperContainer {
		display: inline-block;
		width: 100%;
		margin: 0 !important;
		.searchRecords {
			.item {
				&:first-child {
					border-top: none !important;
				}
			}
		}
	}
}
.Collectiondropdown {
	width: 186px;
	margin: 0px !important;
	.css-1gtu0rj-indicatorContainer {
		transform: rotateX(180deg);
	}
	.css-6j8wv5-Input {
		input[type="text"] {
			grid-area: 1 / 2 / 1 / 1 !important;
		}
	}
	.css-1okebmr-indicatorSeparator {
		display: none;
		visibility: hidden;
	}
	.css-1s2u09g-control {
		border: 1px solid $grey2;
		border-radius: 4px;
		input {
			color: $grey-dark1 !important;
		}
		.css-6j8wv5-Input {
			margin: 0px !important;
			padding: 0px !important;
		}
	}
	svg {
		color: $grey-dark1;
	}
	.css-1hb7zxy-IndicatorsContainer {
		span {
			display: none !important;
		}
	}
	.css-qc6sy-singleValue {
		color: $grey-dark1 !important;
		font-size: 14px;
		font-weight: 500;
		font-family: "Honeywell Sans Web";
		letter-spacing: -0.02em;
	}
	.css-319lph-ValueContainer {
		padding: 10px 12px;
	}
	.css-4ljt47-MenuList {
		padding: 0 !important;
	}
	.css-26l3qy-menu {
		margin-top: -2px !important;
		div {
			color: $grey-dark1 !important;
			font-size: 14px !important;
			font-weight: 500 !important;
			font-family: "Honeywell Sans Web" !important;
			letter-spacing: -0.02em !important;
		}
	}
	.css-9gakcf-option {
		background-color: $light-whilte !important;
		color: $grey-dark1 !important;
	}
}
.Collectiondropdown .css-1n7v3ny-option,
.Collectiondropdown .css-9gakcf-option,
.Collectiondropdown .css-yt9ioa-option {
	background: none !important;
}
.Collectiondropdown .css-1s2u09g-control:hover,
.Collectiondropdown .css-1s2u09g-control:focus,
.Collectiondropdown .css-1s2u09g-control:focus-visible,
.Collectiondropdown .css-1pahdxg-control,
.Collectiondropdown .css-1pahdxg-control:hover {
	border: 1px solid $grey2 !important;
	box-shadow: none !important;
}
