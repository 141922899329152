@import "../../../assets/css/variables.scss";

.request-information {
  margin: 30px 10px 0px 10px;
  h2.requestorInfoTitle {
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 32px;
  }
  p {
    margin: 0;
  }
  .requestorDetails,
  .requestorquery {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    padding-bottom: 20px;
  }
  .requestorquery {
    font-weight: 700;
    color: #303030;
  }
  /*.radioDiv {
    padding-bottom: 30px;
  }*/

  .TCcontent {
    display: inline-block;
    width: 100%;
    padding-top: 35px;
    border-top: 1px solid #d0d0d0;
    .reason-request {
      float: left;
      width: 614px;margin-right: 20px; margin-bottom: 0px;
      &:focus-visible {
        border: none !important;
        outline: 0;
      }
      label {
        color: #303030;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 12px;
      }
      textarea,
      textarea:focus-visible {
        border: 1px solid #f6e6e6;
        outline: 0;    border-radius: 4px;    padding: 10px;width: 100%;
      }
      textarea::placeholder {
        font-size: 16px;
        font-weight: 500;
        font-style: italic;
        line-height: 24px;
        color: #a0a0a0;
        padding-left:0px;
      }
      textarea:focus-within {
        padding:10px;
    }
    }
    .tandc {
      float: left;
      width: 684px;
      padding-left: 16px;
      ul {
        padding: 0;
        margin: 0;
        li:first-child,
        li:nth-child(2) {
          font-weight: 800;
        }
        li {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  .aircraftdetails {
    padding-bottom: 30px;
    padding-top:30px;
    .aircraftdetailsTitle {
      font-size: 16px;
      font-weight: 700;
      color: #000;
      padding-bottom: 12px;
    }
    .aircraft-items.selection {
      width: 300px;
      float: left;
      margin-right: 30px;
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
      svg {
        color: #000;
      }
    }
    .css-tlfecz-indicatorContainer .css-tj5bde-Svg {
      color: #000;
    }

    input[type="text"],
    input[type="text"]:focus,
    input[type="text"]:focus-visible {
      margin-right: 30px;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      padding: 12px;
      outline: 0;
      width: 300px;
    }
    input[type="text"]::placeholder {
      color: #606060;
      font-weight: 500;
      font-size: 16px;
      font-style: italic;

    }
  }
}
.requestformSubmitbtn {
  text-align: right;
}
.requestformSubmitbtn button {
  border: none;
  border-radius: 4px;
  padding: 15px 0px;
  background: #0071b3;
  color: #fff;
}
.requestformSubmitbtn button:hover {
  border: none;
}
.requestformSubmitbtn button:disabled,
.requestformSubmitbtn button[disabled] {
  background: #dfe0df;
  font-weight: 700;
  font-size: 14px;
  color: #606060;
  text-transform: uppercase;
}
.unassigned-aircraft {
  background: #707070;
  color: white;
  border-radius: 4px;
  padding: 5px 0px;
}
.assigned-checkmark {
  color: white;
  border-radius: 4px;
  min-height: 32px;
  padding: 5px 0px 5px 9px;
  background: url("../RequestForm/images/unassignedtick.svg") no-repeat 8px 9px
    #7eb338;
}

.tableComponent .tablecontainer .request-form-pub-table th {
  text-transform: uppercase;
}
.tableComponent .tablecontainer .request-form-pub-table thead th:first-child {
  width: 300px !important;
}
.requestformSubmitbtn {
  text-align: right;    margin-top: 15px;
}
.requestformSubmitbtn button {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border: none;
  border-radius: 4px;
  padding: 15px 0px;
  background: #0071b3;
  color: #fff;
}
.requestformSubmitbtn button:hover {
  border: none;
  background: #0071b3;
}
.requestformSubmitbtn button:disabled,
.requestformSubmitbtn button[disabled] {
  background: #dfe0df;
  font-weight: 700;
  font-size: 14px;
  color: #606060;
  text-transform: uppercase;
}
.unassigned-aircraft {
  background: #707070;
  color: white;
  border-radius: 4px;
  padding: 5px 0px;
}
.assigned-checkmark {
  color: white;
  border-radius: 4px;
  min-height: 32px;
  padding: 5px 0px 5px 25px;
  background: url("../RequestForm/images/unassignedtick.svg") no-repeat 8px 9px
    #7eb338;
}

.tableComponent .tablecontainer .request-form-pub-table th {
  text-transform: uppercase;
}
.tableComponent .tablecontainer .request-form-pub-table thead th:first-child {
  width: 300px !important;
}
.tableComponent .tablecontainer .request-form-pub-table thead th:nth-child(3),
.tableComponent .tablecontainer .request-form-pub-table thead th:nth-child(2),
.tableComponent .tablecontainer .request-form-pub-table thead th:nth-child(4) {
  width: 270px !important;
}

.tableComponent .tablecontainer .request-form-pub-table thead th:nth-child(5) {
  text-align: center;
  width: 170px !important;
}
.tableComponent .tablecontainer .request-form-pub-table tbody td:last-child {
  padding-right: 10px !important;
}
.modal .modal-content .modal-body.requestformPopup p {
  font-size: 20px;
  font-weight: 600;
}
.modal .modal-content .modal-body.requestformPopup .error-content-1
{
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px 55px;
}
.modal .modal-content .modal-body.requestformPopup .error-content-2
{
  font-size: 20px;
  font-weight: 600;
  color: $grey-dark2;
  margin: 0 0 10px 0px;
}
.tableComponent .tablecontainer .request-form-pub-table tbody td:last-child, .tableComponent .tablecontainer .request-form-pub-table thead th:last-child{
  padding-right: 10px !important;
}

.aircraft-items.selection .css-26l3qy-menu > div {
  padding-top: 16px !important;
  max-height: 250px !important;
}
.aircraft-items.selection .css-1okebmr-indicatorSeparator {
  display: none;
  visibility: hidden;
}
.aircraft-items.selection .css-1s2u09g-control {
  border: 1px solid $grey2 !important;
  border-radius: 4px;
}
.aircraft-items.selection .css-1s2u09g-control:hover,
.css-1s2u09g-control:focus,
.css-1s2u09g-control:focus-visible {
  border: 1px solid $blue-bg !important;
}
.aircraft-items.selection .css-1gtu0rj-indicatorContainer {
  transform: rotateX(180deg);
}
.aircraft-items.selection .css-qc6sy-singleValue {
  font-size: 16px;
  font-weight: 500;
  color: $grey-dark1;
}
.aircraft-items.selection .css-1s2u09g-control div {
  padding: 0;
}
.aircraft-items.selection .css-14el2xx-placeholder {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  color: $grey-dark2;
}
.aircraft-items.selection .css-26l3qy-menu > div {
  overflow-y: scroll;
  scrollbar-color: $grey2 $white;
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background: $grey2;
    border-radius: 8px;
    height: 32px;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
}

.aircraft-items.selection .css-1insrsq-control,
.aircraft-items.selection .css-1s2u09g-control,
.css-1insrsq-control {
  padding: 10px;
  min-height: 32px !important;
  &:focus {
    border-color: $grey2 !important;
    box-shadow: none !important;
  }
}
.aircraft-items.selection .css-1insrsq-control {
  padding: 5px 10px !important;
}

.aircraft-items.selection .css-1s2u09g-control.Menu_Open {
  border: 1px solid $blue-bg;
}

.aircraft-items.selection .css-1insrsq-control {
  padding: 5px 10px !important;
}
.aircraft-items.selection .css-1s2u09g-control.Menu_Open {
  border: 1px solid $blue-bg;
}

.aircraft-items.selection .css-26l3qy-menu {
  margin: -2px 0 0 0 !important;
  border: 1px solid $blue-bg;
  border-top: none;
  border-radius: 0px;
}
.aircraft-items.selection .css-26l3qy-menu .css-4ljt47-MenuList div,
.aircraft-items.selection
  .css-26l3qy-menu
  .aircraft-items.selection
  .css-26l3qy-menu
  .css-4ljt47-MenuList
  .css-1n7v3ny-option,
.aircraft-items.selection
  .css-26l3qy-menu
  .css-4ljt47-MenuList
  .css-yt9ioa-option {
  background: none;
  color: $grey-dark1;
}
.aircraft-items.selection .css-26l3qy-menu .css-9gakcf-option,
.aircraft-items.selection .css-26l3qy-menu .css-1n7v3ny-option,
.aircraft-items.selection .css-26l3qy-menu .css-yt9ioa-option {
  background: none !important;
  color: $grey-dark1 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 0px 0px 24px 9px;
}

.aircraft-items.selection {
  .css-6j8wv5-Input {
    display: inline-flex;
    margin: 0 !important;
    padding: 0 !important;
    input[type="text"] {
      grid-area: 1 / 2 / 1 / 1 !important;
    }
  }

  .css-1pahdxg-control {
    padding: 5px 0px 5px 9px;
    border-color: $blue-bg !important;
    box-shadow: none !important;
    &:hover {
      border-color: $blue-bg !important;
      box-shadow: none !important;
    }
    &:focus {
      border-color: $blue-bg !important;
      box-shadow: none !important;
    }
    &:active {
      border-color: $blue-bg !important;
      box-shadow: none !important;
    }
  }
  .css-319lph-ValueContainer {
    padding: 0 !important;
    .css-qc6sy-singleValue {
      color: $grey-dark1 !important;
      font-size: 16px;
      font-weight: $fw-reg;
    }
    .css-14el2xx-placeholder {
      font-weight: $fw-reg;
      font-size: 16px;
      font-style: italic;
      line-height: 24px;
      color: $grey-dark2;
    }
  }
  .css-1gtu0rj-indicatorContainer {
    transform: rotateX(180deg);
  }
  .css-1hb7zxy-IndicatorsContainer {
    .css-tlfecz-indicatorContainer {
      .css-tj5bde-Svg {
        color: $grey-dark1;
      }
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
      visibility: hidden;
    }
  }
  .css-26l3qy-menu {
    margin: -2px 0 0 0 !important;
    border: 1px solid $blue-bg;
    border-top: none;
    border-radius: 0px;

    .css-9gakcf-option {
      background: none !important;
      color: $grey-dark1 !important;
      font-size: 16px !important;
      font-weight: $fw-reg !important;
      padding: 0px 0px 24px 9px;
    }
    .css-1n7v3ny-option {
      background: none !important;
      color: $grey-dark1 !important;
      font-size: 16px !important;
      font-weight: $fw-reg !important;
      padding: 0px 0px 24px 9px;
    }
    .css-yt9ioa-option {
      background: none !important;
      color: $grey-dark1 !important;
      font-size: 16px !important;
      font-weight: $fw-reg !important;
      padding: 0px 0px 24px 9px;
    }
  }
}

.request-information .radioLeft input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 50%;
  display: grid;
  height: 17px;
  margin: 0;
  place-content: center;
  -webkit-transform: translate(-0.075em, -0.075em);
  transform: translate(-0.075em, -0.075em);
  width: 17px;
  position: relative;
  top: 3px;
}

.request-information .radioLeft {
  border-radius: 50%;
  display: inline-flex;
  left: 3px;
  position: relative;
  top: -3px;
  vertical-align: bottom;
  width: 100px;
}
.request-information .radioLeft input[type="radio"]:before {
  border-radius: 50%;
  box-shadow: inset 1em 1em #fff;
  content: "";
  height: 10px;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: -webkit-transform 0.12s ease-in-out;
  transition: transform 0.12s ease-in-out;
  transition: transform 0.12s ease-in-out, -webkit-transform 0.12s ease-in-out;
  width: 10px;
}
.request-information .radioLeft input[type="radio"]:checked {
  background-color: #0071b3;
  border: none;
}
.request-information .radioLeft input[type="radio"]:checked:before {
  background-color: #0071b3;
  box-shadow: inset 1em 1em #fff;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}
.request-information .radioLeft label {
  color: #303030;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0 !important;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.request-information.popup{margin-top: 10px;margin-left: 0;}
.request-information.popup .TCcontent{border: none;padding: 0;}
.request-information.popup .TCcontent .reason-request{float: none; width: auto;}
.request-information.popup .TCcontent .reason-request label{padding-bottom: 5px;}
.request-information.popup  .TCcontent .tandc{float: none; width: auto;padding-top: 20px; padding-left: 25px;}

.submitPopup.modal{--bs-modal-width: 986px !important;}
.submitPopup .modal-content {width:1020px;}
.requestformSubmitbtn .loader{display: block !important;}
.casedetails {margin-top: 15px; margin-bottom: 30px;}
.casedetails div {
  display: inline-block;padding-right: 100px;
}
.casedetails span {
  display: block; font-size: 18px;
  font-weight: 700; color: #303030;
}
.casedetails span:first-child{font-weight: 500; font-size: 18px; color: #404040;}
.submitPopup  .tableComponent .tablecontainer .request-form-pub-table thead th:nth-child(5){width: 215px !important;}
.submitPopup  .modal-title::before {
  background: url("../SearchResults/images/greentick.svg") no-repeat 10px
    10px $grey27 !important;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 48px;
  left: 0px;
  position: absolute;
  width: 48px;
  top: 13px;
}
.submitPopup .modal-title {
  padding-left: 65px;
}
.submitPopup .modal-body{padding-left: 65px !important;}
.submitPopup .modal-body .tableComponent{
  margin-left: 0;
  padding:0px;

}
.submitPopup .tableComponent .tablecontainer .request-form-pub-table thead th:nth-child(5) {
  width: 185px !important;
}
.submitPopup .tableComponent .tablecontainer .request-form-pub-table .assigned-checkmark {padding: 5px 0px 5px 25px;}
.submitPopup .recentdownloadTable {
  display: none;
}
.fetchingData-loading{
background: url("../RequestForm/images/loading-gif.gif") no-repeat center;
width: 500px;
height: 500px;
margin: 0 auto; text-align: center;
}
.fetchingData-loading .loadermessage{ font-size: 18px; font-weight: 700;} 
.cls-applicationDropdown{
  width:300px;
}
.aircraftdetailsTitle{
font-size: 16px;
font-weight: 700;
color: $black;
padding-bottom: 20px;
}