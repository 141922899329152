/*.div-center {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}
.spinner-border {
  --bs-spinner-width: 4rem;
  --bs-spinner-height: 4rem;
}
*/

.loader {
  position: relative;
  top: 9px;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 35px;
  height: 35px;
  margin-left: 6px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
 animation: spin 2s linear infinite;
 left:50%;
 z-index: 9999;
//  display:none
}