@import "../../../../assets/css/variables.scss";

.calendarAndInputWrapper {
  width: 100%;
}

.calendarView {
  position: absolute;
  z-index: 100;
  margin-top: -9px;
  padding: 0px 8px 7px 8px;
}

.p-datepicker {
  border: none;
  font-family: "Honeywell Sans Web";
}

.CalendarRange {
  width: 100%;
  font-size: 12px;

  input {
    display: none;
  }
  // calendar icon button
  .p-datepicker-trigger {
    display: none;
  }
  // arrows for select options
  .commonSelect {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-size: 22px;
  }

  .selectOpen {
    @extend .commonSelect;
    background: url("./icons/arrow-up.svg") no-repeat right center;
  }

  .selectClose {
    @extend .commonSelect;
    background: url("./icons/arrow-down.svg") no-repeat right center;
  }

  select:first-of-type:not(:focus) {
    @extend .selectClose;
  }

  select:first-of-type:focus {
    @extend .selectOpen;
  }

  select:last-of-type:not(:focus) {
    @extend .selectClose;
  }

  select:last-of-type:focus {
    @extend .selectOpen;
  }
}

// small size of th header
.p-datepicker table th {
  padding: 0rem;
  font-size: small;
  font-weight: 800;
  text-align: center;
  margin: 0px;
}

.p-datepicker table td {
  padding: 0rem;
  font-size: small;

  span[aria-disabled="false"] {
    color: black;
    font-weight: 500;
  }

  span {
    border-radius: 0px;
    margin: 0px;
  }

  .p-highlight {
    background-color: $blueLight;
    color: $black;
  }
  .p-highlight-blue {
    background-color: $blue;
    color: $white !important;
    border-radius: 5px;
  }
}

.p-datepicker-today span {
  background-color: transparent;
  border: 1px solid $grey3;
  border-radius: 5px !important;
  span {
    border: none;
  }
}

.p-datepicker-header {
  height: 54px;
  border-bottom: none;

  .p-datepicker-title {
    width: 100%;

    .p-datepicker-month:first-child {
      text-align: center;
      width: 55%;
      margin-left: 20px;
    }

    .p-datepicker-month:last-child {
      width: 35%;
    }

    .p-datepicker-month {
      flex-wrap: wrap;
      border: none;
      font-size: small;
      font-weight: 900;
      outline: none;

      option[data-pc-section="option"] {
        color: $black;
        text-align: left;
      }
    }

    .p-datepicker-month:hover {
      color: $blue;
    }
    .p-datepicker-month:disabled:hover {
      color: $grey12;
    }
  }
}

.p-datepicker-group {
  border-bottom: 1px solid $grey2;
  margin-bottom: 15px;
}

.p-datepicker-group:first-child {
  border-left: none;
  border-right: 1px solid $grey2;
}

.p-datepicker-group:last-child {
  .p-datepicker-header {
    .p-datepicker-month:last-child {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      pointer-events: none;
      background: none;
    }
    .p-datepicker-month:last-child:hover {
      color: $grey12;
    }
  }
}