@import "../../../assets/css/variables.scss";

.modalbodycontentlic.modalbodycontent {
  padding-left: 0px;
}
.modal-footer,
.footercontainerlic {
  margin-bottom: 24px;
  margin-top: 0px;
  .btn:hover {
    border: none;
  }
}
.pdf-modal{
  &.modal {
    .modal-dialog{
      width: 100%;
    }
  }
}
.licagreementpopup {
  &.modal {
    .modal-content {
      padding-bottom: 48px;
    }
  }
  .modalbodycontentlic {
    // padding-left: 24px;
    height: 300px;
    .modal-header {
      .btn-close {
        font-size: 12px;
        position: relative;
        top: -8px;
      }
    }
    .modal-body {
      p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
          font-size: 20px;
          padding: 15px;
          font-weight: 600;
        }
      }
    }
    .modal-footer {
      .footercontainerlic {
        .geocheckbtns {
          width: 100%;
          .leftbtn {
            float: left;
            .lic-agreement-btn{background: none;}
            .downloadButton{margin-top: 10px;}
            .downloadbtn {
              color: $blue-bg !important;
              background: $white !important;
              border: none;
              text-decoration: none;
              min-width: auto;
              font-family: "Honeywell Sans Web";
              font-style: normal;
              font-weight: 700;
              margin: 0;
              font-size: 14px;
              a {
                text-decoration: none;
              }
            }
          }
          .rightbtn {
            float: right;
            display: flex;
            .loder-css {
              padding: 0px 50px 12px 40px;
            }
          }
        }
      }
    }
  }
}
.licagreementpopup .modalbodycontentlic .modal-body.Modal-body-lic {
  height: 200px;
  overflow-y: auto;
  margin-top: 18px;
  margin-bottom: 29px;
  padding-right: 16px;
  scrollbar-color: $black $white;
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    height: 32px;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
}

.licagreementpopup .modal-content .modal-header .modaltitle::before,
.licagreementpopup .modal-content .modal-header .h4::before,
.licagreementpopup .modal-content .modal-header .modal-title::before {
  height: 48px;
  width: 48px;
  background: none;
  border-radius: 50%;
  display: inline-block;
  content: "";
  left: 24px;
  position: absolute;
}
