@import "../../../assets/css/variables.scss";

.AdminSiteCls{
  background-color: $white;
  position:absolute;
  width: 250px;
  border-radius: 4px;
  padding: 10px;
  right: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  top: 32px;
}
.parentrow{
  position: relative;
}
.AdminLinks{position: relative;}