@import "../../../../assets/css/variables.scss";

.CalendarInputWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.CalendarInput {
  outline: none;
  width: 100%;
  border: 1px solid $grey2;
  border-radius: 5px;
  padding: 13px 35px 13px 10px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
}

.CalendarIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding-right: 5px;
}

.invalid {
  border: 1px solid rgb(207, 39, 39);
}

.externalPlaceHolder {
  position: absolute;
  margin-top: -25px;
  right: 0px;
  font-size: 10px;
}
