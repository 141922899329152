@import "../../../../assets/css/variables.scss";

.CalendarFooter {
  display: flex;

  button {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }

  .todaySection {
    display: flex;
    flex: 1;

    .todayBtn {
      padding: 7px 10px;
      border-radius: 5px;
      background-color: transparent;
      color: $blue;
      border: 1.5px solid transparent;
    }
    .todayBtn:hover {
      color: $blue;
    }
  }

  .applyAndCancelSection {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .cancelBtn {
      padding: 7px 20px;
      border-radius: 5px;
      background-color: $white !important;
      color: $blue !important;
      border: 1.5px solid $blue !important;
      margin-right: 15px;
    }
    .applyBtn {
      padding: 7px 20px;
      border-radius: 5px;
      border: 1.5px solid transparent;
    }
    .applyBtn:disabled {
      background-color: $grey5 !important;
      color: $grey1 !important;
    }
    .applyBtn:enabled {
      background-color: $blue !important;
      color: $white !important;
    }
  }
}
