@import "../../../assets/css/variables.scss";
.Leftpanel {
  float: left;
  width: 26%;
}
.header-serach-result {
  visibility: hidden;
  display: none;
}
/*.header-serach-result {
  padding: 15px;
  justify-content: space-between;
  display: flex;
  padding: 10px 42px;
}
.sortby-relevance {
  height: 40px;
  width: 220px;
  left: 0px;

  border-radius: 4px;
  padding: 0px 4px 0px 12px;
}
.panel {
  border-radius: 0;
  border: 1px solid #d0d0d0;
  width: 25%;

  padding: 15px;
  margin-left: 15px;
  .panel-heading {
    padding: 2px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid #d0d0d0;
    background: linear-gradient(0deg, #d0d0d0, #d0d0d0),
      linear-gradient(0deg, #f3f3f3, #f3f3f3);
  }
  .clear {
    float: right;
    position: relative;
    top: 5px;
    line-height: 21px;
    z-index: 2;
  }
  .panel-title {
    position: relative;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 900;
    color: #303030;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0;
  }
}
.custom-checkbox {
  padding: 10px;
}

.fav-class {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
  gap: 10px;
  color: #0071b3;
  font-weight: 600;
  width: 200px;
  height: 162px;
  right: 0px;
  top: 17px;
  background: #f3f3f3;
  border-radius: 4px;
  margin: 15px 15px 15px auto;
}*/
.searchtipcontainer {
  display: none;
}

.WrapperContainer {
  margin: 25px 12px 0 12px;
  .searchRecords {
    width: 72%;
    float: left;
    margin-left: 24px;
    .item {
      /* &:first-child {border-top: 1.43px solid $grey-bg4;}*/
      .itemTitle {
        h6 {
          font-size: 18px;
          font-weight: 700;
          color: $grey-dark1;
          margin-top: 15px;
          margin-bottom: 16px;
          text-align: justify;
        }
      }
      border-bottom: 1px solid $grey-bg4;
      padding-bottom: 24px;
      &:last-child {
        border-bottom: none;
      }
      &:first-child {
        border-top: 1px solid $grey-bg4;
        margin-top: 22px;
      }
      .metafield {
        display: inline-block;
        width: 100%;
        margin: 0px 15px 16px 0px;
        .metafieldLeft {
          width: 25%;
          float: left;
          font-weight: 700;
          color: $black;
          font-size: 14px;
        }
        .metafieldright {
          float: left;
          font-weight: 500;
          color: $black;
          font-size: 14px;
          padding-left: 16px;
          width: 75%;
        }
      }

      .shomorelink {
        font-size: 14px;
        font-weight: 700;
        color: $blue-bg;
        text-transform: uppercase;
        cursor: pointer;
        .arrow {
          border: solid $blue-bg;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 4px;
          position: relative;
          top: -4px;
          left: 13px;
        }
        .arrow.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        .arrow.up {
          transform: rotate(224deg);
          -webkit-transform: rotate(224deg);
          top: 2px;
        }
      }
      .showlesslink {
        display: none;
        font-size: 14px;
        font-weight: 700;
        color: $blue-bg;
        text-transform: uppercase;
        .arrow {
          border: solid $blue-bg;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          position: relative;
          top: -4px;
          left: 13px;
        }
        .arrow.up {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
          top: 1px;
        }
      }
    }
  }
}

.erro-msg {
  font-size: 32px;
  font-weight: 700;
  color: $black;
  p {
    margin-bottom: 0;
  }
}
.erro-msg.searchresults {
  margin-top: -35px;
}
.erro-msg.NodataAvailable {
  text-align: center;
  padding-top: 50px;
}
.show-more {
  display: block;
}
.show-less {
  display: none !important;
  width: 85%;
  margin: auto;
  .searchRecords {
    width: 70%;
    float: right;
    margin-left: 24px;
    .item {
      border-bottom: 1px solid $grey-bg4;
      padding-bottom: 40px;
      width: 100%;
      display: inline-block;
      .seacrhresultLeft {
        width: 74.7%;
        float: left;
      }
      .rightpanel {
        width: 200px;
        float: left;
        margin-left: 18px;
        min-height: 300px;
        visibility: hidden;
      }
      .itemTitle {
        h6 {
          font-size: 18px;
          font-weight: 700;
          color: $grey-dark1;
          margin-top: 15px;
          margin-bottom: 16px;
          text-transform: uppercase;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      .metafield {
        display: inline-block;
        width: 100%;
        margin: 0px 15px 16px 0px;
        .metafieldLeft {
          width: 21%;
          float: left;
          font-weight: 700;
          color: $black;
          font-size: 14px;
        }
        .metafieldright {
          float: left;
          font-weight: 500;
          color: $black;
          font-size: 14px;
          padding-left: 16px;
        }
      }
      .shomorelink {
        font-size: 14px;
        font-weight: 700;
        color: $blue-bg;
        text-transform: uppercase;
        a {
          text-decoration: none;
        }
        .arrow {
          border: solid $blue-bg;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          position: relative;
          top: -4px;
          left: 13px;
        }
        .arrow.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
      .showlesslink {
        display: none;
        font-size: 14px;
        font-weight: 700;
        color: $blue-bg;
        text-transform: uppercase;
        a {
          text-decoration: none;
        }
        .arrow {
          border: solid $blue-bg;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          position: relative;
          top: -4px;
          left: 13px;
        }
        .arrow.up {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
          top: 1px;
        }
      }
    }
  }
}
.seacrhresultLeft {
  float: left;
  width: 74.7%;
}
.rightpanel {
  /*width: 205px;*/
  width: 222px;
  float: right;
  margin-left: 18px;
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: $grey35;
  border-radius: 4px;
  /*.fav-class {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      background: #f3f3f3;
      border-radius: 4px;
     }*/
  .fav-class {
    span {
      padding-bottom: 14px;
      a {
        color: $blue-bg;
        letter-spacing: -0.02em;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 14px;
        text-decoration: none;
        padding-left: 25px;
      }
    }
  }
}
.item {
  display: inline-block;
  width: 100%;
}

.relatedPubTitle {
  margin-left: 12px;
  border-top: 1px solid $grey2;
  padding-top: 32px;
  margin-bottom: 0;
  width: 98.5%;
}
.related-publication .searchRecords .item:first-child {
  border-top: none !important;
}
.ViewcollectionContainer.related-publication {
  padding-top: 10px !important;
  margin-top: 0px !important;
  margin-left: 0;
}
.TopmetaData {
  padding: 0px 0px 0px 12px !important;
  margin: 0px !important;
}
.TopmetaData .searchRecords .item:first-child {
  border-top: 1.43px solid $grey-bg4;
  margin-top: 24px !important;
}
.startover {
  width: auto;
}

.startover .btn-primary .btn {
  background-color: $white;
  color: $blue-bg;
  border-radius: 0;

  font-weight: 800;
  margin: 0;
  background: url("../SearchResults/images/startover.svg") no-repeat 11px 14px;
  padding: 10px 5px 10px 32px;
  min-width: 150px;
}
.view-all-link {
  color: $blue-bg;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}
.viewallPopupdialog.modal {
  pointer-events: none;
  .modal-content {
    padding: 0;
    .viewallpopupPagination {
      li {
        list-style-type: none;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .modal-header {
      background: $grey-bg4;
      padding: 10px 15px;
      .btn-close {
        font-weight: 700;
        color: $grey-dark9;
        font-size: 10px;
        opacity: 1;
      }
      .partnumheader {
        font-size: 24px;
        font-weight: 500;
        color: $grey-dark9;
        margin: 0;
      }
    }
    .SearchresultsPopup {
      margin-top: 15px;
      ul {
        display: inline-table;
        li {
          list-style-type: none;
          list-style-type: none;
          font-size: 14px;
          font-weight: 500;
          color: $black;
          padding: 3px 0px;
          font-family: "Honeywell Sans Web";
        }
      }
    }
    .viewallPagination {
      text-align: center;
      ul {
        border: none;
        padding-bottom: 25px;
        padding-top: 20px;
        width: auto !important;
      }
    }
  }
  .modal-dialog {
    width: 844px !important;
    padding: 0;
  }
}

.searchResultsPagination .myfavpagination ul {
  border: none;
}
