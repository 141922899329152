@import "../../../assets/css/variables.scss";
.RelatedPubCheckbox {
    text-align: left;
    .leftcheckbox{
        position: relative;
        top: 6px;
        
    }
    .leftcheckbox input[type=checkbox] {
        opacity: 0;
    }
    .leftcheckbox input[type=checkbox] {
        opacity: 0;
    }

    
    label.isOptional {
        padding-left: 5px; 
        font-weight: 500; 
        font-size: 14px; 
        color: $grey-dark2;}
}
.addEmailPopup.modal .modalbodycontent { padding: 0px;}
.modal .modal-content .modal-body p.addwatchlistMsg{margin-bottom: 0px;}
.addremovePopup .modalbodycontent{padding-left: 0px;}
.savepubbtn{border:1px solid $blue-bg !important; top: 2px; position: relative;}