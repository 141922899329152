@import "../../../assets/css/variables.scss";

.addFav {
  background: url("../RecentlyDownload/images/addfavicon.svg") no-repeat 0 0;
  display: inline-block;
  height: 28px;
  width: 28px;}

.removeFav {
  background: url("../RecentlyDownload/images/removeicon.svg") no-repeat 0 0;
  display: inline-block;
  height: 28px;
  width: 28px;}

.addFav:hover, .removeFav:hover {
  cursor: pointer;}