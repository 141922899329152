@import "../../../assets/css/variables.scss";


.tableComponent {
  width: 100%;
  margin: 0 10px;
  h1 {
    margin: 24px 10px 32px 0px;
    font-weight: 700;
    font-size: 32px;
    color: $grey-dark1;
  }
  .tablecontainer {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: 1px solid $grey21;
    border-bottom: none;
    width: 99%;
    .TableWrapper {
      
      th {
        font-size: 14px;
        font-weight: 700;
        color: $grey8;
        background: $grey6;
      }
      thead {
        th {
          padding: 16px 0px 16px 8px !important;
          &:first-child {
            width: 408px !important;
          }
          &:nth-child(4) {
            width: 123px !important;
          }
          &:nth-child(5) {
            width: 89px !important;
            text-align: center;
          }
          &:nth-child(6) {
            width: 186px !important;
            text-align: center;
          }
          a {
            text-decoration: none;
          }
        }
      }
      tbody {
        td {
          font-size: 16px;
          font-weight: 500;
          color: $grey-dark1;
          font-family: "Honeywell Sans Web";
          &:last-child {
            text-align: center;
          }
        }
        td.pubtitlecell {
          a {
            font-size: 16px;
            font-weight: 700;
            font-family: "Honeywell Sans Web";
            text-decoration: none;
            display: block;
          }
        }
      }
    }
  }
}
.tableComponent .tablecontainer .TableWrapper tbody td,
.TableWrapper thead th {
  padding: 16px 0px 16px 8px !important;
}
.tableComponent .tablecontainer .TableWrapper thead th:nth-child(2),
.TableWrapper thead th:nth-child(3) {
  width: 203px !important;
}
.TableWrapper {
  tbody {
    td {
      &:last-child {
        a {
          background: url("../MyFavorites/images/trashicon.svg") no-repeat 0px
            0px;
          display: inline-block;
          height: 28px;
          width: 28px;
        }
      }
      .leftcheckbox {
        border: 1px solid $grey2;
        border-radius: 4px;
        color: $grey3;
        height: 20px;
        width: 20px;
        display: inline-block;
      }
      .leftcheckbox input[type="checkbox"] {
       -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        height: 20px !important;
        left: -2px;
        opacity: 0; 
        top: -2px;
        width: 20px;
      }
      .leftcheckbox.checked {
        background: url("../GeoCheck/images/tickicon.svg") no-repeat 2px 4px
        $blue-bg;
        border: 1px solid $blue-bg;
        border-radius: 4px;
      }
    }
    td.checkboxcell {
      text-align: center;
    }
  }
}

.licagreementpopup .modalbodycontentPopups {
  height: auto;
}
.licagreementpopup .modalbodycontentPopups .modal-footer .footercontainerlic {
  margin-top: 30px;
}
.Popupwrapper.modal .modal-content {
  padding-bottom: 0;
}
.licagreementpopup .modalbodycontentPopups {
  padding-left: 0;
}
.leftcheckbox {
  border: 1px solid $grey2;
  border-radius: 4px;
  color: $grey3;
  height: 20px;
  width: 20px;
  display: inline-block;
}
.leftcheckbox input[type="checkbox"] {
 /* -webkit-appearance: none;
  -moz-appearance: none; */
  cursor: pointer;
  height: 20px !important;
  left: -2px;
 /* opacity: 0; */
  top: -2px;
  width: 20px;
}
.leftcheckbox.checked {
  background: url("../GeoCheck/images/tickicon.svg") no-repeat 2px 4px $blue-bg;
  border: 1px solid $blue-bg;
  border-radius: 4px;
}
.licagreementpopup .modalbodycontentPopups {
  height: auto;
}
.licagreementpopup .modalbodycontentPopups .modal-footer .footercontainerlic {
  margin-top: 15px;
}
.Popupwrapper.modal .modal-content {
  padding-bottom: 0;
}
.licagreementpopup .modalbodycontentPopups {
  padding-left: 0;
}
.myfavpopup .modal-content {width: 500px;}
.myfavpopup .modal-content .popupmsg {
  padding: 0!important;
}
.tableComponent .mylibtablecontainer .mylibtable thead th:nth-child(4), .tableComponent .mylibtablecontainer .mylibtable thead th:nth-child(3) {
  width: 230px !important;
}
.no-record-found{text-align: center !important;}
.tableComponent.downloadsTble .tablecontainer .TableWrapper tbody td.no-record-found {
  text-align: center !important;
}
.tableComponent .mylibtablecontainer .mylibtable tbody td:last-child {
  text-align: left;
}
