.parent {
  display: flex;
  flex: 1;
}

.left {
  flex: 1;
  align-items: flex-start;
}

.right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
